import React, { Component } from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Container from "../components/container";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaQuestion,
  FaServer,
  FaLaptop,
  FaArrowUp,
  FaCubes
} from "react-icons/fa";
import styles from "./crm-cennik.module.css";
import Qa from "../components/qa";
import { Link } from "gatsby";
import ReactTooltip from "react-tooltip";
import SEO from "../components/seo";
import TryButton from "../components/TryButton";
import Columns from "../components/columns";
import Lightbox from "../components/lightBox";

export const query = graphql`
  query {
   zdjecie6: file(relativePath: { eq: "szanse-sprzedazy-nowe.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie5: file(relativePath: { eq: "kalendarz-kawalek.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
           }
      }
    }
     zdjecie4: file(relativePath: { eq: "karta-kontrahenta-zrzut.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
          }
      }
    }
     zdjecie3: file(relativePath: { eq: "widok-glowny.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
          }
      }
    }
     zdjecie2: file(relativePath: { eq: "raporty-crm-nowe.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
       
        }
      }
    }
  }
`;

const DuzaFirma = ({ data }) => {
  return (
    <Layout title="Prosty CRM dla dużej firmy?" headline="Twoja firma ma powyżej 50 pracowników i potrzebujesz dla niej odpowiedniego systemu?">
      <SEO
        title="CRM dla dużej firmy?"
        description=" System CRM dla dużej firmy ✅ Szanse sprzedaży • Koordynacja pracy • Baza wiedzy o klientach • Wypróbuj za darmo!"
      />
        <Hero maxHeight="280px" justifyContent="center">
          <h1>CRM dla dużej firmy?</h1>
          <p>Twoja firma ma powyżej 50 pracowników i potrzebujesz dla niej odpowiedniego systemu?</p>
        </Hero>
        <Container>
           <Columns>
    <div>
     <p>Szukasz systemu CRM dla dużej firmy? Problemy dużych przedsiębiorstw różnią się znacząco od trudności, 
     z którymi borykają się mniejsze firmy. Na tym etapie rozwoju przedsiębiorcy często szukają
     oprogramowania, które <strong>wesprze ich w&nbsp;zarządzaniu wieloma procesami, zadba o przepływ informacji i&nbsp;pomoże planować pracę wielu użytkowników. Dodatkowo ważna okazuje się integracja z systemami ERP. </strong></p>

     <p>Gotowe i podstawowe funkcjonalności mogą nie sprostać wymaganiom dużej firmy. Dlatego dla takich firm przygotowaliśmy siostrzane rozwiązanie -  <strong>dedykowany pakiet systemu <a href="https://synergiuscrm.pl">SynergiusCRM.</a>
     </strong></p>
</div>
<div>
<Lightbox images={[data.zdjecie3]} 
      alts={["widok główny prosty system crm"]}/>
      <br />
</div>
</Columns>
<br />
<br />
<center><h4> Jesteśmy dostawcą sprawdzonych rozwiązań CRM dla dużych firm. Wspieramy obszar marketingu, sprzedaży i obsługi klienta. </h4></center>
<br />
<br />
<br />
<center><h2> Podstawowe obszary, które wspiera CRM dla dużej firmy </h2> </center>
<br />
<br />
<Columns>
<div>
<center><h3> Zarządzanie szansami sprzedaży </h3></center>
<p>Jedym z najważniejszych zadań systemu CRM dla dużej firmy jest wsparcie w zarządzaniu wieloma, różnymi procesami sprzedaży i ich koordynacja. Kluczowe znaczenie ma tutaj podział i specjalizacja pracy, a także niezakłócony przepływ informacji. Te wyzwania z sukcesem wspiera funkcja zarządzania szansami sprzedaży, która dodatkowo przyspiesza pracę handlowców &nbsp;pomaga im osiągnąć założone cele. </p>
</div>
<div>
<Lightbox images={[data.zdjecie6]} 
      alts={["zarządzanie szansami sprzedaży"]}/>


</div>
</Columns>

<br />
<br />
<Columns>
<div>

<Lightbox images={[data.zdjecie5]} 
      alts={["koordynacja pracy w systemie crm"]}/>
     
     
</div>
<div>
<center><h3> Planowanie pracy </h3></center>
<p>Planowanie pracy w systemie CRM dla dużej firmy staje się prostsze dzięki współdzielonym kalendarzom, przypomnieniom, liście zadań na dziś, wiązaniu zadań z różnymi obiektami w systemie (kontrahentem, szansą sprzedaży).
Kierownik zyskuje dostęp do zawsze aktualnych informacji na temat działań zespołu sprzedażowego. Natomiast handlowcy otrzymali narzędzie, które wspiera ich w pamiętaniu o ważnych zadaniach.</p></div>
</Columns>
<br />
<br />
<Columns>
<div>
<center><h3> Baza wiedzy o kontrahentach </h3></center>
<p>Wszystkie informacje o klientach i partnerach biznesowych w systemie CRM znajdują się w jednym miejscu. 
System CRM <strong>zbiera wszystkie informacje o&nbsp;spotkaniach 
czy ustaleniach</strong>.  Dzięki temu z łatwością możesz
zastąpić nieobecnego pracownika. Nowa osoba zobaczy całą historię współpracy. Dane są <strong>zawsze dostępne, a do tego zawsze bezpieczne</strong>. </p>
</div>
<div>
<Lightbox images={[data.zdjecie4]} 
      alts={["baza wiedzy o klientach"]}/>
      

</div>
</Columns>
<br />
<br />
<Columns>
<div>
<Lightbox images={[data.zdjecie2]} 
      alts={["baza wiedzy o klientach"]}/>

</div>
<div>
<center><h3> Analiza sprzedaży </h3></center>
<p> Ułatwiona kontrola działań pracowników, raporty sprzedażowe, 
przyczyny utraty oraz wiele innych raportów sprawi, że <strong>analiza poszczególnych procesów w firmie</strong> będzie przebiegać o wiele sprawniej.
Dedykowane rozwiązania oferują także <strong>stworzenie raportów dopasowanych do specyfiki Twojego biznesu</strong>.  </p>
      

</div>
</Columns>
<br />
<br />
<br />
<br />

        </Container>
     </Layout>
  );
};

export default DuzaFirma;
